import {project} from "../../utils/constant.urls";

interface CustomNode {
  type: string;
  children: {
    text: string;
  }[];
}

export default class BlogsModel {
  title: string = "";
  content: CustomNode[] = [{type: "paragraph", children: [{text: ""}]}];
  // tags: string[];
  author: string = "";
  slug: string = "";
  avatar: string = "";
  subTitle: string = "";
  image: string = "";
  project: string = "";
  updateAt: Date | null = null;

  constructor(data?: {[field: string]: any}) {
    this.title = data?.title ?? "";
    this.content = data?.content ?? [{type: "paragraph", children: [{text: ""}]}];
    this.author = data?.author ?? "";
    this.slug = data?.slug ?? "";
    this.avatar = data?.avatar ?? "";
    this.subTitle = data?.subTitle ?? "";
    // this.tags = data?.tags ?? [];
    this.image = data?.image ?? "";
    this.project = project ?? "";

    this.updateAt = data?.updateAt
      ? typeof data?.updateAt === "string"
        ? new Date(data.updateAt)
        : data.updateAt instanceof Date
        ? data.updateAt
        : new Date(data.updateAt.toDate().toString())
      : null;
  }

  toJson(): BlogsModel {
    return Object.assign({}, this);
  }
}
